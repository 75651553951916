import { FC, ReactNode } from 'react'

interface FormInputRowProps {
  id?: string
  label?: string
  title?: ReactNode
  note?: ReactNode
  children?: any
  disabled?: boolean
}

const FormInputRow: FC<FormInputRowProps> = ({
  id,
  label,
  title,
  children,
  note,
  disabled,
}) => {
  return (
    <div
      className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-3"
      id={id}
    >
      <label
        htmlFor={label}
        className={`block text-sm font-medium ${
          disabled ? 'text-gray-300' : 'text-gray-500'
        } sm:mt-px sm:pt-2`}
      >
        {title}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="w-full flex flex-col rounded-md">
          {children}
          {note && (
            <p className="text-xs text-gray-500 py-3">
              <b>Note: &nbsp;</b>
              {note}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
export default FormInputRow
